export default class Overlay {
	constructor() {
		this.overlayNode = null;
		this.init();
	}

	init() {
		const overlayNode = document.createElement('div');
		overlayNode.classList.add('overlay');
		overlayNode.setAttribute('data-js', 'overlay');
		document.body.insertAdjacentElement('beforeend', overlayNode);
		this.overlayNode = overlayNode;
	}

	show() {
		if (!this.overlayNode) {
			return;
		}

		this.overlayNode.classList.add('active');
	}

	hide() {
		if (!this.overlayNode) {
			return;
		}

		this.overlayNode.classList.remove('active');

		if (this.overlayListener) {
			removeEventListener('click', this.overlayListener);
		}
	}

	addListenerOnClose(callback) {
		if (!this.overlayNode) {
			return;
		}

		this.overlayListener = this.overlayNode.addEventListener('click', () => {
			callback();
			this.hide();
		});
	}
}