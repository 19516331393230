import './components/aiSelect.js';
import { contactSubmit } from './contact.js';
import Contact from "./services/Contact.js";
import FilterUnits from './services/FilterUnits.js';
import Map from "./services/Map.js";
import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";

document.addEventListener('DOMContentLoaded', () => {
	const overlay = new Overlay();
	const locationsMap1 = new Map('[data-map="locations1"]', '[data-map-coords="locations1"]');
	const locationsMap2 = new Map('[data-map="locations2"]', '[data-map-coords="locations2"]');
	const heroSlider = new Slider('[data-slider="hero-images"]');
	const formSubscribe = new Contact('[data-form="subscribe"]', [
		'email',
	]);
	const facilitySlider = new Slider('[data-slider="facility-images"]');
	const filterUnits = new FilterUnits('[data-js="facility-rates-node"]');
	const reviewsSlider = new Slider('[data-slider="reviews"]');
	const singleFacilityMap = new Map('[data-map="single-facility"]');

	if (document.getElementById('contact_form')) {
		contactSubmit();
	}

	function _addDoubleClick() {
		[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
			el.addEventListener('click', e => {
				if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
					if (!el.hasAttribute('clicked')) {
						e.preventDefault();
						console.log('clicked');
						el.setAttribute('clicked', true);
					}
				}
			});
		});
	}

	

	// Check scroll, update header
	(() => {
		const headerScroll = document.querySelector('[data-js="header-scroll"]');
	
		_addDoubleClick();


		if (!headerScroll) {
			return;
		}

		const checkScroll = () => {
			if (window.scrollY > 0) {
				headerScroll.classList.add('scroll');
			} else {
				headerScroll.classList.remove('scroll');
			}
		};
	
		checkScroll();
		window.addEventListener('scroll', checkScroll);
	})();

	// Toggle Mobile Menu
	(() => {
		const navOpenBtn = document.querySelector('[data-js="nav-open"]');
		const navCloseBtn = document.querySelector('[data-js="nav-close"]');
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		if (!navMenu) {
			return;
		}

		if (navOpenBtn) {
			navOpenBtn.addEventListener("click", () => {
				navMenu.classList.add("active");
				overlay.show();
				overlay.addListenerOnClose(() => {
					navMenu.classList.remove("active");
				});
			});
		}

		if (navCloseBtn) {
			navCloseBtn.addEventListener("click", () => {
				navMenu.classList.remove("active");
				overlay.hide();
			});
		}
	})();

	//change header opacity based on page location
	(() => {
		const header = document.querySelector('header');
		const locationsSection = document.querySelector('.locations');

		if (locationsSection) {
			header.style.backgroundColor = 'rgba(0, 0, 0, 0.16)';
		}
	})();

	// Change Specials
	(() => {
		const changeSpecials = [...document.querySelectorAll('[data-js="change-special"]')];

		if (!changeSpecials.length) {
			return;
		}

		let currentIndex = 0;

		const fadeInNextIcon = () => {
			changeSpecials.forEach(icon => icon.classList.remove('active'));
			changeSpecials[currentIndex].classList.add('active');
			currentIndex = (currentIndex + 1) % changeSpecials.length;
		};
	
		setInterval(fadeInNextIcon, 2000);
		fadeInNextIcon();
	})();
}, true);
